import React, { FormEvent, useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/styles";

import Button from "./Button";
import { info, login, LoginResponse } from "./service";
import TextField from "./TextField";
import SnackbarContent from "./SnackbarContent";

import evnLogo from "./evn.png";

const useStyles = makeStyles({
  loginContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 300,
    margin: "auto",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: 40,
    width: "100%",
  },
  logo: {
    width: 135,
  },
  loginForm: {
    marginTop: 10,
  },
  action: {
    width: "100%",
    marginTop: 20,
  },
  field: {
    width: "100%",
    marginBottom: 20,
  },
});

const loadingMessage = "Verificando dados";
const userNotFoundMessage = "Usuário e/ou senha não encontrados, por favor tente novamente";
const loginSuccessMessage = "Pronto! Você está sendo redirecionado para nossa Central de Atendimento!";

function App() {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [token, setToken] = useState("");
  const [snack, setSnack] = useState<{
    open: boolean,
    message: string,
    variant: "success" | "warning" | "error" | "info",
  }>({
    open: false,
    message: "",
    variant: "info",
  });

  useEffect(() => {
    info().then(setToken);

    return () => {
      setUsername("");
      setPassword("");
      setFormValid(false);
      setIsBusy(false);
    };
  }, []);

  useEffect(() => {
    setFormValid(username.trim().length >= 3 && !!password.trim());
  }, [username, password]);

  function loginSuccess(response: LoginResponse) {
    if (response.errorMessage) {
      loginFail(response.errorMessage);
      return;
    }

    window.location.href = response.redirectTo;

    setSnack({
      open: true,
      variant: "success",
      message: loginSuccessMessage,
    })
  }

  function loginFail(message?: string) {
    setIsBusy(false);

    setSnack({
      open: true,
      variant: "error",
      message: message || userNotFoundMessage,
    });
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();
    setIsBusy(true);

    setSnack({
      open: true,
      variant: "info",
      message: loadingMessage,
    })

    formValid && login(token, { username, password })
      .then(loginSuccess)
      .catch(() => loginFail());
  }

  function handleSnackbarClose() {
    setSnack({
      ...snack,
      open: false,
    })
  }

  return (
    <div className={classes.loginContainer}>
      <Paper>
        <div className={classes.wrapper}>
          <img src={evnLogo} className={classes.logo} alt="Logo Escola Via Net" />
          <form className={classes.loginForm} onSubmit={onSubmit}>
            <TextField
              required
              className={classes.field}
              name="username"
              label="Usuário EVN"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              required
              className={classes.field}
              label="Senha EVN"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              className={classes.action}
              type="submit"
              disabled={!formValid || isBusy}
            >
              ENTRAR
            </Button>
          </form>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
        open={snack.open}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          variant={snack.variant}
          message={snack.message}
          onClose={handleSnackbarClose} />
      </Snackbar>
    </div>
  );
}

export default App;
