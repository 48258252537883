import Button from "@material-ui/core/Button";
import { Theme, withStyles } from "@material-ui/core/styles";

const LoginButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText("#f58220"),
    backgroundColor: "#f58220",
    "&:hover": {
      backgroundColor: "#f78f29",
    },
    "&:disabled": {
      backgroundColor: "rgba(245, 130, 32, 0.65)",
    },
  },
}))(Button);

export default LoginButton;
