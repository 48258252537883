import axios from "axios";

const service = axios.create();

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  errorMessage: string;
  redirectTo: string;
}

export async function login(token: string, request: LoginRequest): Promise<LoginResponse> {
  const headers: {[index: string]: string} = {
    "Content-Type": "application/json",
  }

  if (token) {
    headers["x-csrf-token"] = token;
  }

  return service.post("/api/auth/login/", request, { headers }).then(resp => resp.data);
}

export async function info(): Promise<string> {
  return service.get("/api/auth/info/", {
    headers: {
      "Content-Type": "application/json",
    },
  }).then(resp => resp.headers["x-csrf-token"]);
}

export default service;
