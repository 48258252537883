import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const LoginTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#c30253",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c30253",
    },
  },
})(TextField);

export default LoginTextField;
